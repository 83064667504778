
import { computed, defineComponent, onMounted, ref } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb'
import { useI18n } from 'vue-i18n'
import * as Yup from 'yup'
import { useStore } from 'vuex'
import moment from 'moment'
import { Actions, Mutations } from '@/store/enums/StoreEnums'
import router from '@/router'
import { event } from 'vue-gtag'

interface ProfileDetails {
  name: string
  surname: string
  contactPhone: string
  address: string
  birthday: string
  cin: string
  speciality: string
  civilstatus: string
}

export default defineComponent({
  name: 'profile-settings',
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const { t } = useI18n()
    const submitButton = ref<HTMLButtonElement | null>(null)
    const updatePasswordButton = ref<HTMLElement | null>(null)
    const updateEmailButton = ref<HTMLElement | null>(null)

    const passwordFormDisplay = ref(false)
    const emailFormDisplay = ref(false)
    const incorrectPassword = ref<boolean>(false)
    const updatedPassword = ref<boolean>(false)
    const updatedEmail = ref<boolean>(false)
    const incorrectPasswordEmail = ref<boolean>(false)
    const unavailableEmail = ref<boolean>(false)

    const email = computed(() => store.getters.currentUser.email)

    const store = useStore()
    const user = store.getters.currentUser

    const profileDetailsValidator = Yup.object().shape({
      firstName: Yup.string().required(t('editProfile.requiredFirstname')),
      lastName: Yup.string().required(t('editProfile.requiredLastname')),
      phone: Yup.string()
        .test('is-phone-number', t('editProfile.invalidPhone'), (value) => {
          if (value == undefined) return false
          return value.length == 8
        })
        .required(t('editProfile.requiredPhone')),
      // adress: Yup.string().required(t("editProfile.requiredAddress")),
      adress: Yup.string(),

      // civilStatus: Yup.string().required(t("editProfile.requiredCivilstatus")),
      civilStatus: Yup.string(),

      cin: Yup.string()
        .test('is-cin-number', t('editProfile.invalidCin'), (value) => {
          if (value == undefined) return false
          return (
            (value.startsWith('0') || value.startsWith('1')) &&
            value.length == 8
          )
        })
        .required(t('editProfile.requiredCin')),
      // speciality: Yup.string().required(t("editProfile.requiredSpeciality")),
      speciality: Yup.string(),
    })

    const changePassword = Yup.object().shape({
      oldPassword: Yup.string().required(
        t('editProfile.requiredCurrentPassword')
      ),
      nPassword: Yup.string()
        .min(4, t('editProfile.passwordNewCondition'))
        .required(t('editProfile.requiredNewPassword')),
      cPassword: Yup.string()
        .min(4, t('editProfile.passwordConfirmationCondition'))
        .required(t('editProfile.requiredConfirmPassword'))
        .oneOf(
          [Yup.ref('nPassword'), null],
          t('editProfile.passwordMustMatch')
        ),
    })

    const changeEmail = Yup.object().shape({
      email: Yup.string()
        .required(t('editProfile.requiredEmail'))
        .email(t('editProfile.invalidEmail')),
      password: Yup.string()
        .min(4, t('editProfile.passwordConfirmationCondition'))
        .required(t('editProfile.requiredConfirmPassword')),
    })

    const profileDetails = ref<ProfileDetails>({
      name: '',
      surname: '',
      contactPhone: '',
      address: '',
      birthday: '',
      civilstatus: '',
      cin: '',
      speciality: '',
    })

    const saveChanges = async (values) => {
      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute('data-kt-indicator', 'on')

        // Send login request
        await store.dispatch(Actions.UPDATE_USER, values)
        const ErrorMessage = store.getters.getError
        if (ErrorMessage == '') {
          user.employee.firstName = values.firstName
          user.employee.lastName = values.lastName
          user.employee.phone = values.phone
          user.employee.adress = values.adress
          user.employee.cin = values.cin
          user.employee.civilStatus = values.civilStatus
          user.employee.birthDate = values.birthDate
          user.speciality = values.speciality
          store.commit(Mutations.SET_USER, user)
          store.commit(Mutations.SET_UPDATED_PROFILE_ALERT, true)
          router.push({ name: 'profile-overview' })
          submitButton.value?.removeAttribute('data-kt-indicator')
          event('Update profile info', {
            event_category: 'Profile teacher',
            event_label: 'Profile settings',
            value: 1,
          })
        }
      }
    }

    const updatePassword = async (values) => {
      if (updatePasswordButton.value) {
        // Activate indicator
        updatePasswordButton.value.setAttribute('data-kt-indicator', 'on')
        incorrectPassword.value = false
        updatedPassword.value = false

        try {
          await store.dispatch(Actions.UPDATE_PASSWORD, values)
          updatedPassword.value = true
          passwordFormDisplay.value = false
          setTimeout(() => {
            updatedPassword.value = false
          }, 5000)
          event('Update password', {
            event_category: 'Profile teacher',
            event_label: 'Profile settings',
            value: 1,
          })
        } catch (e) {
          incorrectPassword.value = true
          setTimeout(() => {
            incorrectPassword.value = false
          }, 5000)
        }

        updatePasswordButton.value?.removeAttribute('data-kt-indicator')
      }
    }

    const updateEmail = async (values) => {
      if (updateEmailButton.value) {
        // Activate indicator
        updateEmailButton.value.setAttribute('data-kt-indicator', 'on')
        incorrectPasswordEmail.value = false
        unavailableEmail.value = false
        updatedEmail.value = false

        try {
          await store.dispatch(Actions.UPDATE_EMAIL, {
            password: values.password,
            username: values.email,
          })
          updatedEmail.value = true
          emailFormDisplay.value = false
          user.email = values.email.toLowerCase()
          setTimeout(() => {
            updatedEmail.value = false
          }, 5000)
          event('Update email', {
            event_category: 'Profile teacher',
            event_label: 'Profile settings',
            value: 1,
          })
        } catch (e) {
          const ErrorMessage = store.getters.getError
          if (ErrorMessage == 'Mot de passe incorrect !') {
            incorrectPasswordEmail.value = true
            setTimeout(() => {
              incorrectPasswordEmail.value = false
            }, 5000)
          } else {
            unavailableEmail.value = true
            setTimeout(() => {
              unavailableEmail.value = false
            }, 5000)
          }
        }

        updateEmailButton.value?.removeAttribute('data-kt-indicator')
      }
    }

    onMounted(() => {
      setCurrentPageBreadcrumbs(t('editProfile.settings'), [t('profile')])
      profileDetails.value.name = user.employee.firstName
      profileDetails.value.surname = user.employee.lastName
      profileDetails.value.contactPhone = user.employee.phone
      profileDetails.value.address = user.employee.adress
      profileDetails.value.cin = user.employee.cin
      profileDetails.value.speciality = user.speciality
      profileDetails.value.civilstatus = user.employee.civilStatus
      profileDetails.value.birthday = moment(user.employee.birthDate).format(
        'YYYY-MM-DD'
      )
    })
    return {
      t,
      email,
      incorrectPassword,
      updatedPassword,
      updatedEmail,
      submitButton,
      saveChanges,
      profileDetails,
      passwordFormDisplay,
      emailFormDisplay,
      profileDetailsValidator,
      changePassword,
      changeEmail,
      updatePasswordButton,
      updateEmailButton,
      updatePassword,
      updateEmail,
      incorrectPasswordEmail,
      unavailableEmail,
    }
  },
})
